@mixin listCommon {
  ul {
    font-size: 14pt;
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: 15px;
  }
  li {
    margin-top: 5px;
    margin-left: 15px;
  }
  ol {
    font-size: 14pt;
    list-style-type: decimal;
    margin-left: 35px;
  }
}

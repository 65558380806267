@import "src/Introduction/baseDrawing.module";
@import "src/Introduction/TableMagic.module";
@import 'src/styles/theme';
.drawing0 {
  @include drawing-base;
}
.drawing1 {
  @include drawing-base;
}
.drawing2 {
  @include drawing-base;
}

.drawing3 {
  @include drawing-base;
}

.drawing4 {
  @include drawing-base;
}

.drawing5 {
  @include drawing-base;
}

.drawing6 {
  @include drawing-base;
}

.mapArea1 {
  @include drawing-base;
}
.spacerDiv {
  margin-top: 5vh;
  margin-bottom: 5vh;
  min-height: 20vh;
  //background: aliceblue;
}
.content {
  @include table-magic;
  @include textLink;
  @include textCommon;
  @include resultWinners;
  @include listCommon;

  ul li {
    margin-top: 10px;
  }
}

.textContent {
  margin-left: 5vw;
  max-width: 60%;
}

.caption {
  font-size: 10pt !important;
  font-style: italic;
  max-width: 70%;
  margin-left: 10%;
}
.pewIdeology {
  max-width: 80%;
  align-content: center;
}

.flyover {
  padding: 5px;
  border: 5px solid blue;
  background: rgba(225, 225, 255, .7);
  min-width: 300px;
}
.sideBySide {
  display: flex;
  flex-direction: row;
}
@import '../../../styles/theme';

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 10px 10px;
  justify-content: space-around;
}

.textSection, .imageSection {
  max-width: 60%;
}

.supertext {
  font-size: 14px;
  font-weight: lighter;
  text-transform: uppercase;
}

.title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 18px;
}

.mainText {
  font-size: 16px;
  font-weight: normal;
}

.imageSection {
  max-width: 20%;
}

.imageContent {
  object-fit: contain;
  max-width: 100%;
}
@mixin drawing-base {
  position: sticky;
  background: white;
  top: 10vh;
  left: 30vw;
  width: 70vw;
  height: 80vh;
  svg canvas {
    position: absolute;
    top: 0;
    left: 0;
  }

  :global(#slidersDiv) {
    position: absolute;
    top: 85%;
    width: 20%;
    left: 60%;
    z-index: 10000;
    transform: translate3d(0, 0, 10000px);
    border: none;
    flex-direction: column;
    .sliderOuter {
      opacity: .5;
    }
    div {
      border: none;
    }
  }
}

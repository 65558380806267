@mixin table-magic {
  caption {
    caption-side: bottom;
    font-size: 10pt;
    font-style: italic;
  }
  table {
    font-size: 12pt;
    //width: 15vw;
    margin: 10px 5px;
  }

  th {
    background: #eAeAeA;
    font-weight: bold;
    align-content: normal;
    border: 1px solid black;
    padding: 3px;
  }

  td {
    border: 1px solid black;
    padding: 5px 15px;
    text-align: center;
  }


  @media (orientation: landscape) and (max-height: 1600px) {
    table {
      font-size: 10pt;
      margin: 2px;
    }
    th {
      border: 0.9px solid black;
      padding: 3px;
    }

    td {
      border: 0.7px solid black;
      padding: 2px 5px;
    }
  }
  @media (orientation: landscape) and (max-height: 650px) {
    table {
      font-size: 6pt;
      margin: 1px;
    }
    th td {
      border: 0.9px solid black;
    }
    th {
      padding: 1px;
    }

    td {
      padding: 1px;
    }
  }

  @media (orientation: portrait) and (max-width: 1600px) {
    table {
      font-size: 10px;
      margin: 5px 2px;
    }
    td th {
      border: 0.1px solid black;
    }
    th {
      padding: 2px 5px;
    }
    td {
      padding: 2px 5px;
    }
  }
}
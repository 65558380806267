@import '../DataStory.module';
@import '../Introduction/TableMagic.module';

.resultBox {
  //border: 1px solid black;
  //margin: 3px;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  h3 {
    margin-top: 0;
    paddding-left: 0;
    margin-left: 0;
  }
  @include table-magic;
}

.winnerAndScore {
  div {
    margin-left: 0px;
    padding: 3px;
  }
}

.caption {
  font-style: italic;
  font-size: 8pt !important;
}

.generalResults {
  table {
    width: 100%;
  }
}

.blackBorder {
  border: 1px solid black;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px;
  max-width: 40vw;
}

.titleDiv {
  font-size: 18pt;
  font-weight: bold;
  height: 35px;
  padding-bottom: 10px;
  padding-top: 10px;

  @media (orientation: landscape) and (max-height: 900px) {
    font-size: 14pt;
    height: 20px;
  }

  @media (orientation: landscape) and (max-height: 650px) {
    font-size: 12pt;
    height: 20px;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  @media (orientation: portrait) {
    font-size: 14pt;
    height: 20px;
  }

}
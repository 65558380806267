.content {

}

.content > ul {
  margin-bottom: 36px;
}

.content > ul > li {
  margin: 12px 0px;
}
@import '../DataStory.module';


.winnerAndScore {
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 100%;
  align-items: stretch;
  font-size: 24pt;
  @media (orientation: landscape) and (max-height: 900px) {
    font-size: 16pt;
    .representationCaption {
      font-size: 8pt;
    }
  }
  @media (orientation: landscape) and (max-height: 650px) {
    .representationCaption {
      font-size: 7pt;
    }
    font-size: 12pt;
  }
  @media (orientation: portrait) {
    font-size: 16pt;
  }
}

.candidateNameAndCaption {
  flex: 1;
}

.candidateName {
  text-align: center;
  flex: 1;
}

.representationDiv {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  margin-left: 10px;
  flex: 1;

}

.representationCaption {
  font-size: 10pt;
  //@media (orientation: landscape) and (max-width: 1100px) {
  //  font-size: 8pt;
  //}
  //@media (orientation: portrait) {
  //  font-size: 8pt;
  //}
}

.representationNumber {

}
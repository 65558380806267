@import "baseDrawing.module";
@import "src/Introduction/TableMagic.module";
@import "src/styles/ListCommon.module";
@import "src/styles/flyover.module";
@import "src/styles/centeredResultBox.module";
@import '../styles/theme';

.drawing0 {
  @include drawing-base;
}

.drawing1 {
  @include drawing-base;

}

.drawing2 {
  @include drawing-base;
}

.drawing3 {
  @include drawing-base;
}

.drawing4 {
  @include drawing-base;
}

.drawing5 {
  @include drawing-base;
}

.drawing6 {
  @include drawing-base;
}

.mapArea1 {
  @include drawing-base;
}

.spacerDiv {
  margin-top: 5vh;
  margin-bottom: 5vh;
  min-height: 20vh;
  //background: aliceblue;
}
.content {
  @include table-magic;
  @include textLink;
  @include textCommon;
  @include resultWinners;
  @include listCommon;
}

.flyover {
  @include flyover;
}

.singleResultBox {
  width: 60%;
}

.sideBySide {
  display: flex;
  flex-direction: row;
}
.sideBySideBox {
  width: 45%;
}

.th {
  white-space: nowrap
}

.stepElement {
}

.pullQuoteStory {
   font-size: 14pt;
   font-style: italic;
   margin: 20px 2vw;
   border-top: 1px solid black;
   border-bottom: 1px solid black;
 }

@import '../styles/theme';

.htmlArea, .svgArea, .canvasArea {
  opacity: 1;
  pointer-events: auto;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 1px);

}

.divArea {
  //position: fixed;
  z-index: 0;
  -webkit-transform: translate3d(0, 0, 0);
}
@import '../../styles/theme';

.content {
  text-align: center;
  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.titleText {
  font-size: 42px;
  font-weight: normal;
}

.subtextDiv {
  max-width: 80%
}

.titleSubtext {
  font-size: 16pt;
  margin-top: 10px;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 10px;
}
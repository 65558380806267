@import 'src/styles/theme';
@import 'src/styles/TableMagic.module';

.stepElement {
  margin: 24px 30vh 0 0;
  z-index: 200;
  transform: translate3d(0, 0, 200px);
  //pointer-events: none;
  p, ul, li {
    @include textLink;
    max-width: 40vw;
  }

  b {
    font-weight: bold;
  }

  p {
    padding-top: 20px;
  }

  i {
    font-style: italic;
  }

  ul, li {
    margin: 30px 30px;
  }

  table {
    margin: 20px;
    pointer-events: auto;
  }

  caption {
    font-weight: bold;
  }
  @include table-magic;
}

@import '../../../styles/theme';

.content {
  padding-top: 72px;
  width: 60%;
  margin: 0px auto;
  font-size: 18px;
  line-height: 27px;

  h2, h4 {
    line-height: 1.2;
  }
}

h3 {
  margin-top: 24px;
}


.compactList {
  li {
    margin: 10px 10px;
  }
}

.pullQuote {
  font-size: 16pt;
  margin-top: 30px !important;
  margin-bottom: 20px;
  margin-left: 5vw;
  margin-right: 5vw;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.hardQuote {
  font-size: 16pt;
  font-style: italic;
  margin-top: 30px !important;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 5vw;
  margin-right: 5vw;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.stepsList {

  li {
    list-style-type: none;
    margin-bottom: 5px;
    margin-left: 40px;
  }
}

.hillaryImage {
  maxWidth: calc(min(600px, 80vw))
}

.textContent {
  p, ul, li {
    @include textLink;
  }

  p {
    margin-top: 15px;
  }

  i {
    font-style: italic;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    margin: 30px 30px;
  }

  caption {
    font-weight: bold;
  }


  th {
    font-weight: bold;
    align-content: normal;
    border: 1px solid black;
    padding: 5px;
    background: #eAeAeA
  }

  b {
    font-weight: bold;
  }

  td {
    border: 1px solid black;
    padding: 10px;
  }
}

.visTitle {
  font-size: 18pt;
  font-weight: bold;
  text-align: center;
}

.caption {
  font-style: italic;
  font-size: 10pt;
  line-height: 1.3em;
  width: 80%;
  margin-left: 10%;
  margin-top: 0;
}

.winner {
  background: lightgreen;
}

.loser {
  background: lightcoral;
}

.resultsDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;

  table {
    margin: 12px;
  }

  th, td {
    padding: 2px 10px;
  }

  .winner {
    background: lightgreen;
  }

  .loser {
    background: lightcoral;
  }
}

.rankingsCaption {
  line-height: 1.6;
  font-size: 8pt;
  caption-side: bottom;
  font-weight: normal !important;
  text-align: left;
}

.rankingsDiv {
  div {
    display: inline-block;
    flex-direction: row;
    vertical-align: top;
  }

  table {
    width: 150px;
    padding: 10px;
    margin: 20px 60px 5px 0;
  }

  th, td {
    padding: 2px 10px;
  }
}

.rankingsDescription {
  p {
    font-size: 8pt;
    max-width: 180px;
  }
}

.centerText {
  th, td {
    align-content: center;
    text-align: center;
    background: #61dafb;
  }
}

.small {
  font-size: 8pt;
}

.tableNumber {
  text-align: right;
}

.bold {
  font-weight: bold;
}

@media (max-width: 1200px) {
  .content {
    width: 80%;
  }
}

@media (max-width: 700px) {
  .content {
    width: 90%;
  }
}

@import "../content/Article/Article.module.scss";
@import "src/Introduction/baseDrawing.module";
@import "src/styles/flyover.module";

.textContent {
  h2 {
    padding-top: 60px;
  }
}

@mixin floatingElement {
  @include drawing-base;
  margin-top: 30px;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(min(80vw, 50vh))
}

.floatingElement {
  @include floatingElement;
}

.dividedHouse {
  @include floatingElement;
  height: calc(min(60vw, 500px));
}

.consensusCongress {
  @include floatingElement;
}

.simulatedHouse {
  @include floatingElement;
}

.flyover {
  @include flyover;
}

.fiftyOne {
  max-width: calc(max(60%, 600px))
}

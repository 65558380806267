.editCandidate {
  display: none;
  position: absolute;
  top: 50px;
  left: 50px;
  pointer-events: auto;
  white-space: nowrap;
  background: aliceblue;
  padding: 10px;
  border: 10px solid slateblue;
}
.editCandidateName {
  text-align: center;
  font-weight: bold;
}
.editInputLabel {
  font-size: 10pt;
}
.editCandidate {

}
@import '../../../styles/theme';

.content {
  background-color: $background--gray;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  padding: 12px;
  border-radius: 4px;
}

.title {
  font-weight: bold;
  font-size: 18px;
}

.description {
  font-weight: normal;
  font-size: 16px;
}

.mainBox {
  transition: all .15s;
}

.mainBox:hover {
  box-shadow: 0 0 11px rgba(50,50,50,.3);
}
@import './imports/colors';
@import './imports/animations';
@import '../../styles/ListCommon.module.scss';

@mixin textLink {
  a {
    //margin-left: .5em;
    //margin-right: .5em;
  }
  a:link {
    color: blue;
    text-decoration: underline;
  }
  a:visited {
    color: purple;
    text-decoration: underline;
  }
  a:hover {
    color: red;
    text-decoration: underline;
  }
}

@mixin baseText {
  b {
    font-weight: bold;
  }
  i {
    font-style: italic;
  }
}

@mixin resultWinners {
  :global(.winningCandidate) {
    font-weight: bold;
  }

  :global(.eliminatedCandidate) {
    text-decoration: line-through;
  }

  :global(.ignoredCandidate) {
    opacity: .2;
  }
}

@mixin textCommon {

  .pullQuote {
    font-size: 16pt;
    font-style: italic;
    margin: 50px 5vw;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }

  @include textLink;
  @include baseText;
  p {
    font-size: 14pt;
  }
  h5 {
    font-size: 16pt;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 18pt
  }
  p {
    margin-top: 15px;
    line-height: 1.6;
  }
  @media screen and (max-height: 800px) {
    h3 {
      font-size: 12pt
    }
    p {
      font-size: 10pt;
    }
  }
}


.centered {
  text-align: center;
}

.leftAligned {
  text-align: left;
}

.rightAligned {
  text-align: right;
}

.faded {
  opacity: 0.4;
}
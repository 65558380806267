@import '../../../styles/theme';


.floatingImage {
  width: 250px;
  height: auto;
  object-fit: contain;
  margin: 10px;

  @media (orientation: landscape) and (max-height: 900px) {
    width: 100px;
  }

  @media (orientation: portrait) and (max-height: 1800px) {
    width: 100px;
  }

}

.imageBlock {
  p {
    margin: 24px;
  }

  display: flex;
  flex-direction: row;
  border: 1px solid black;
  padding-bottom: 34px;
}

.content {
  display: flex;
  flex-direction: horizontal;
  width: 100%;
  align-items: top;
  justify-content: flex-start;
}

.content > * {
  width: 50%;
  padding: 24px 24px 24px 36px;
}
@import '../../styles/theme';

.step {
  @include baseText;
  position: relative;
  left: 5vw;
  //min-height: 100vh;
  margin-bottom: 20vh;
  padding-bottom: 20vh;
  padding-top: 20vh;
  width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  pointer-events: auto;
  z-index: 1;
  -webkit-transform: translate3d(0,0,1px);

  table {
    pointer-events: auto;
  }

  h1 {
    background: rgba(255, 255, 255, .8);
  }

  h3 {
    background: rgba(255, 255, 255, .8);
    padding: 5px;
  }

  p {
    padding: 5px;
    background: rgba(255, 255, 255, .8);
  }
  ul {
    background: rgba(255, 255, 255, .8);
  }
  ol {
    background: rgba(255, 255, 255, .8);
  }
}

.firstStep {
  margin-top: -80vh;
}

.stepInner {
  max-width: 40vw;
}


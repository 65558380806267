@import '../DataStory.module';
@import 'src/Introduction/TableMagic.module';


.resultBox {
  //border: 1px solid black;
  //margin: 3px;
  //justify-content: flex-start;
  //align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  //width: 15vw;

  h3 {
    margin-top: 0;
    paddding-left: 0;
    margin-left: 0;
  }
  @include table-magic;
}

.blackBorder {
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.caption {
  font-size: 6pt;
  font-style: italic;
}

.titleDiv {
  background: rgba(255, 255, 255, .8);
  font-size: 18pt;
  font-weight: bold;
  height: 35px;
  padding-bottom: 10px;
  padding-top: 10px;
  @media (orientation: landscape) and (max-height: 900px) {
    font-size: 12pt;
    height: 20px;
  }
  @media (orientation: landscape) and (max-height: 650px) {
    font-size: 10pt;
    height: 20px;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  @media (orientation: portrait) {
    font-size: 14pt;
    height: 20px;
  }
}

.sliderTitle {
  text-align: center;
}

.sliderOuter {
  margin-top: 20px;
  border: 1px solid black;
  background: white;
}

.leftLabel {
  min-width: 40px;
  margin-top: 0;
  font-size: 10pt;
  text-align: center;
}
.rightLabel {
  min-width: 40px;
  margin-top: 0;
  font-size: 10pt;
  text-align: center;
}

.slider {
  width:10vw;
}

.sliderBox {
  display: flex;
  flex-direction: row;
}

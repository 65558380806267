.supertext {
  font-size: 14px;
  font-weight: lighter;
  text-transform: uppercase;
}

.title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 0px;
}

.content {
  margin-bottom: 20px;
}
@import '../DataStory.module';
@import 'src/Introduction/baseDrawing.module';

.mainDiv {
  //position: fixed;
  //top: 5vh;
  //padding-top: 5vh;
  display: flex;
  flex-wrap: nowrap;
  position: relative;

  :global(#slidersDiv) {
    position: absolute;
    bottom: 10%;
    width: 20%;
    left: 70%;
    z-index: 10000;
    transform: translate3d(0, 0, 10000px);
    border: none;
    flex-direction: column;

    .sliderOuter {
      opacity: .5;
    }

    div {
      border: none;
    }

    @media (max-height: 650px) {
      :global(.sliderOuter) {
        margin: 5px;
      }
      :global(.title) {
        font-size: 8pt;
      }
      :global(.label) {
        font-size: 6pt;
      }
      :global(.slider) {
        width: 5vw;
      }
    }
  }

  @media (max-height: 650px) {
    position: relative;
  }

  flex-direction: row;


  p, ul, li {
    @include textLink;
  }

  b {
    font-weight: bold;
  }

  p {
    padding-top: 20px;
  }

  i {
    font-style: italic;
  }

  ul, li {
    margin: 30px 30px;
  }

  caption {
    font-weight: bold;
  }
}

.resultsAndControls {
  flex-direction: column;
  display: flex;
}

.controls {
  position: absolute;
  width: 100%;
  height: 20vh;
  background: aliceblue;
}

.resultsContainer {
  @include resultWinners;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 50vw;
}

.svgArea, .canvasArea, .htmlArea {
  position: relative;
  top: 0;
  left: 0;
  opacity: 1;

  width: calc(min(50vw, 100vh));
  height: 95vh;

}

.svgArea, .canvasArea {
  position: absolute;
  top: 0;
  left: 0;
}

.buttons {
  position: absolute;
  //top: 10px;
  //left: 10px;
  font-size: 18pt;
  z-index: 2;
  transform: translate3d(0, 0, 2px);

  .helpButton {
    @media (max-height: 900px) {
      font-size: 12pt;
    }
  }
}

.cancelButton {
  position: absolute;
  top: 3px;
  left: calc(100% - 30px);
}

.helpModal {
  position: absolute;
  top: 20vh;
  left: calc(min(10vw, 200px));
  width: calc(min(80vw, 1000px));
  max-height: 60vh;
  z-index: 1000;
  transform: translate3d(0, 0, 1000px);
  opacity: 1;
  background: white;
  border: 5px solid blue;
  display: none;
}


.modalContentDiv {
  margin: 24px;

  h3 {
    padding-bottom: 20px;
  }
}

.modalNav {
  max-height: 15vh;
  border-bottom: 1px solid black;
}

.scrollDiv {
  max-height: 45vh;
  overflow-y: auto
}

.editCandidate {
  //display: none;
  position: absolute;
  top: 50px;
  left: 50px;
  pointer-events: auto;
  white-space: nowrap;
  background: aliceblue;
  padding: 10px;
  border: 10px solid slateblue;
}

.editCandidateName {
  text-align: center;
  font-weight: bold;
}

.editInputLabel {
  font-size: 10pt;
}

.editCandidate {

}
@import '../../styles/theme';

.content {
  border-bottom: 1px solid gray;
  z-index: 10;
  transform: translate3d(0, 0, 10px);
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  //height: 5vh;
}

.logo {
  width: 100%;
}

.nav {
  width: 50%;
}

.separatorDiv {
  min-width: 3px;
}
.links {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  h1 {
    padding: 6px;
    margin: 0px;
  }
}

@media (max-height: 1200px) {
  .content {
    padding: 4px;
    padding-bottom: 1px;
    font-size: 14px;

    h1 {
      padding: 3px;
      font-size: 14pt;
    }
  }
}

.readingOrderButton {
  height: 100%;
  font-size: 14pt;
  color: black;
  border-radius: 5px;
  background-color: lightsteelblue;
  border: 0px;
}

@media (max-height: 800px) {
  .content {
    padding: 2px;
    padding-bottom: 1px;
    font-size: 14px;

    h1 {
      padding: 2px;
      font-size: 12pt;
    }
  }
  .readingOrderButton {
    height: 100%;
    font-size: 10pt;
    color: black;
    border-radius: 5px;
    background-color: lightsteelblue;
    border: 0px;
  }
}
$font--dark: black;
$font--light: white;

$app--blue: #0349A5;
$app--white: white;
$app--black: black;
$app--green: #709E37;
$app--red: #A80F25;
$app--yellow: #FFC43F;

$background--gray: #f7f7f7;

$divider--gray: #CACACA;
@import '../../../styles/theme';

.content {
  background-color: $background--gray;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 12px;
  border-radius: 4px;
}

.textContent {
  max-width: 70%;
  min-width: 200px;
}

.title {
  font-weight: bold;
  font-size: 18px;
}

.imageContent {
  max-width: 150px;
  max-height: 150px;
  padding: 5px;
}

.subtitle {
  font-weight: normal;
  font-size: 16px;
}

.teaser {
  font-weight: normal;
  font-size: 14px;
}

.mainBox {
  transition: all .15s;
}

.mainBox:hover {
  box-shadow: 0 0 11px rgba(50,50,50,.3);
}